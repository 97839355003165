import React, { useEffect, useState, useRef } from 'react';
import { Container, Row } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import { toast } from 'react-toastify';
import { io } from 'socket.io-client';
import { AfterLoginHeader } from '../Header/AfterLoginHeader';
import SideBar from '../SideBar';
import Drawer from 'react-modern-drawer';
import { apiUrl } from '../../config/apiUrl';
import { ROOMID } from '../../utils/contants';
import { isMobileViewHook } from '../../CustomHooks/isMobileViewHook';
import classes from './SideBarSkeleton.module.css';

const SideBarSkeleton = ({ heading, children }) => {
  const socket = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleNewMail = () => {};

  const handleNewMessage = (e) => {
    console.log(e);
    toast.info(e?.message);
  };

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    isMobileViewHook(setIsMobile);
  }, [window.innerWidth]);

  useEffect(() => {
    // setup websocket
    socket.current = io(apiUrl, {
      transports: ['websocket'],
      reconnection: true,
      reconnectionDelay: 180000,
      reconnectionDelayMax: 300000,
    });

    socket?.current?.emit('chatJoin', { roomId: ROOMID, userId: '' });
    socket?.current?.on('newMessage', handleNewMessage);

    return () => {
      socket?.current?.off('newMail');
      socket?.current?.off('newMessage');
    };
  }, []);

  return (
    <>
      <style>{`
        .drawerContainer{
          width:320px !important;
        }
        @media (max-width:768px){
          .drawerContainer{
            width:290px !important;
          }
        }
    `}</style>
      <Container fluid className="g-0">
        <Row className="g-0">
          <div className={[!isMobile && classes.sidebarDiv].join(' ')}>
            {!isMobile ? (
              <SideBar />
            ) : (
              <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction="left"
                className="drawerContainer"
              >
                <SideBar />
              </Drawer>
            )}
          </div>
          <div className={[!isMobile && classes.contentDiv].join(' ')}>
            {isMobile && (
              <GiHamburgerMenu
                className={[classes.GiHamburgerMenu]}
                onClick={() => {
                  toggleDrawer();
                }}
              />
            )}
            <AfterLoginHeader heading={heading} />
            {children}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SideBarSkeleton;
