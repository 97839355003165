export const rolesOptions = [
  // { label: 'Admin', value: 'admin' },
  { label: 'Buyer', value: 'buyer' },
  { label: 'Seller', value: 'seller' },
  { label: 'Broker', value: 'broker' },
  { label: 'Banker', value: 'banker' },
  { label: 'Attorney', value: 'attorney' },
  { label: 'Accountant', value: 'accountant' },
  { label: 'FinancialAnalyst', value: 'financial-analyst' },
  { label: 'BuyerConcierge', value: 'buyer-concierge' },
  { label: 'SellerConcierge', value: 'seller-concierge' },
  { label: 'Executive', value: 'executive' },
];

export const emailTemplateOptions = [
  { label: 'Custom Email', value: 'custom email' },
  { label: 'Newsletter Template', value: 'newsletter template' },
  { label: 'Blast Template', value: 'blast template' },
];

export const outSideUsersOptions = [
  { label: 'Banker', value: 'banker' },
  { label: 'Attorney', value: 'attorney' },
  { label: 'Accountant', value: 'accountant' },
  { label: 'FinancialAnalyst', value: 'financial-analyst' },
  { label: 'BuyerConcierge', value: 'buyer-concierge' },
  { label: 'SellerConcierge', value: 'seller-concierge' },
  { label: 'Executive', value: 'executive' },
];

export const leadSourceOptions = [
  { label: 'BNI', value: 'bni' },
  { label: 'Referral', value: 'referral' },
  { label: 'Website', value: 'website' },
  { label: 'Repeat Customer', value: 'repeat-customer' },
  { label: 'Other Networking', value: 'other-networking' },
  { label: 'Third Party', value: 'third-party' },
  { label: 'Other Brokers', value: 'other-brokers' },
  { label: 'Personal Prospecting', value: 'personal-prospecting' },
  { label: '3rd Party Cold Call', value: '3rd-party-cold-call' },
  { label: 'BBS Cold', value: 'bbs-cold' },
];

export const listingStatusOptions = [
  { label: 'Inquired', value: 'inquired' },
  { label: 'NDA-Submitted', value: 'nda-submitted' },
  { label: 'NDA-signed', value: 'nda-signed' },
  { label: 'Under-Negotiation', value: 'under-negotiation' },
  { label: 'Under-Contract', value: 'under-contract' },
  { label: 'Sold', value: 'sold' },
  { label: 'Not-Interested', value: 'not-interested' },
  { label: 'Not-Qualified', value: 'not-qualified' },
  { label: 'Closed', value: 'closed' },
];

export const outSideUsersOptionsForFilter = [
  { label: 'All', value: 'outside-roles' },
  { label: 'Banker', value: 'banker' },
  { label: 'Attorney', value: 'attorney' },
  { label: 'Accountant', value: 'accountant' },
  { label: 'Co-Broker', value: 'co-broker' },
  { label: 'JobSeeker', value: 'job-seeker' },
  //
  { label: 'Landlord', value: 'landlord' },
  { label: 'Property Manager', value: 'property-manager' },
  { label: 'Job Applicant', value: 'job-applicant' },
  { label: 'Title Company', value: 'title-company' },
  { label: '3rd Party Contacts', value: '3rd-party-contacts' },
  { label: 'Insurance Agent', value: 'insurance-agent' },
  { label: 'Service Provider', value: 'service-provider' },
];
export const adminUsersOptionsForFilter = [
  { label: 'All', value: 'admin-roles' },
  { label: 'FinancialAnalyst', value: 'financial-analyst' },
  { label: 'BuyerConcierge', value: 'buyer-concierge' },
  { label: 'SellerConcierge', value: 'seller-concierge' },
  { label: 'Executive', value: 'executive' },
];

export const fileAndFolderRoleOptions = [
  // { label: 'Admin', value: 'admin' },
  { label: 'Buyer', value: 'buyer' },
  { label: 'Seller', value: 'seller' },
  { label: 'Broker', value: 'broker' },
];
export const leadProgressOptions = [
  // { label: 'Admin', value: 'admin' },
  { label: 'New', value: 'new' },
  { label: 'RefToBroker', value: 'ref-to-broker' },
  { label: 'Called', value: 'called' },
  { label: 'Emailed', value: 'emailed' },
  { label: 'NotInterested', value: 'not-interested' },
  { label: 'NotQualified', value: 'not-qualified' },
];

export const groupReferenceArray = [
  { label: 'group', value: 'group' },
  { label: 'lead-group', value: 'lead-group' },
  { label: 'business-group', value: 'business-group' },
  { label: 'guest', value: 'guest' },
];

export const leadStatusOptions = [
  { label: 'VIP', value: 'VIP' },
  { label: 'Inquired', value: 'inquired' },
  { label: 'NDA-Submitted', value: 'nda-submitted' },
  { label: 'NDA-signed', value: 'nda-signed' },
  { label: 'Under-Negotiation', value: 'under-negotiation' },
  { label: 'Under-Contract', value: 'under-contract' },
  { label: 'Sold', value: 'sold' },
  { label: 'Not-Interested', value: 'not-interested' },
  { label: 'Not-Qualified', value: 'not-qualified' },
];

export const onlinePresenceOptions = [
  { label: 'Facebook', value: 'facebook' },
  { label: 'Google Map', value: 'google-map' },
  { label: 'Yelp', value: 'yelp' },
  { label: 'MapQuest', value: 'map-quest' },
  { label: 'Website', value: 'website' },
  { label: 'Uber Eats', value: 'uber-eats' },
  { label: 'Instagram', value: 'instagram' },
  { label: 'Bing location', value: 'bing-location' },
];

export const createListingstatusOptions = [
  { label: 'Pre-Listing', value: 'pre-listing' },
  { label: 'Active', value: 'active' },
  { label: 'Under-Contract', value: 'under-contract' },
  { label: 'Sold', value: 'sold' },
  { label: 'Off-Market', value: 'off-market' },
  { label: 'BVI-Submitted', value: 'bvi-submitted' },
];
export const amountTypeOptions = [
  {
    label: 'Owned',
    value: 'real-estate',
  },
  {
    label: 'Rented',
    value: 'rent',
  },
  {
    label: 'None',
    value: 'location',
  },
];

export const filterConversationOptions = [
  {
    label: 'All Conversations',
    value: 'all-conversation',
  },
  {
    label: 'Sent to me',
    value: 'sent-to-me',
  },
];
