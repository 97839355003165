import React, { useEffect, useRef, useState } from 'react';
import { MdModeEdit, MdPictureAsPdf } from 'react-icons/md';
import { RiDeleteBinLine, RiFileExcel2Fill } from 'react-icons/ri';
import { AiFillEye } from 'react-icons/ai';
import { FaFileUpload, FaFileCsv, FaFileWord } from 'react-icons/fa';

import classes from './AttachmentUpload.module.css';
import { imageUrl } from '../../config/apiUrl';
import { Button } from '../Button/Button';
import { toast } from 'react-toastify';

function RenderComp({ state }) {
  return (
    <>
      {((typeof state == 'object'
        ? ['png', 'jpeg', 'jpg', 'svg', 'jfif'].includes(
            state?.name?.split('.')[1],
          )
        : ['png', 'jpeg', 'svg+xml', 'jpg', 'jfif'].includes(
            state?.split('.')[1],
          )) && (
        <img
          className={classes.img}
          src={
            typeof state == 'object'
              ? URL.createObjectURL(state)
              : `${imageUrl(state)}`
          }
        />
      )) ||
        ((typeof state == 'object'
          ? ['doc', 'docx'].includes(state?.name?.split('.')[1])
          : state?.split('.')[1] == 'docx') && (
          <FaFileWord className={classes.typeIcon} size={35} />
        )) ||
        ((typeof state == 'object'
          ? ['xlsx']?.includes(state?.name?.split('.')[1])
          : state?.split('.')[1] == 'xlsx') && (
          <RiFileExcel2Fill className={classes.typeIcon} size={35} />
        )) ||
        ((typeof state == 'object'
          ? ['pdf']?.includes(state?.name?.split('.')[1])
          : state?.split('.')[1] == 'pdf') && (
          <MdPictureAsPdf className={classes.typeIcon} size={35} />
        )) ||
        ((typeof state == 'object'
          ? ['csv']?.includes(state?.name?.split('.')[1])
          : state?.split('.')[1] == 'csv') && (
          <FaFileCsv className={classes.typeIcon} size={35} />
        ))}
    </>
  );
}

function AttachmentUpload({ state, setter, edit = true, onDelete }) {
  const inputRef = useRef(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([...state]);
  }, [state]);

  const HandleUploadFile = (e) => {
    if (e.target.files?.length == 0) return toast.error('File is reuqired');
    for (var i = 0; e.target.files[i]; i++)
      if (e.target.files[i]?.type == 'mp4')
        return toast.warn('Invalid file type');
    setter(e.target.files);
  };
  return (
    <div className={classes.box}>
      {data?.length >= 1 ? (
        <>
          {data.slice(0, 3).map((ite, idx) => (
            <div className={classes.csvBox}>
              <RenderComp state={ite} />
              {/* On Hover */}
              <div className={classes.viewBtnBox}>
                <Button
                  leftIcon={<AiFillEye />}
                  className={classes.icon}
                  onClick={() =>
                    window.open(
                      typeof ite == 'object'
                        ? URL.createObjectURL(ite)
                        : `${imageUrl(ite)}`,
                    )
                  }
                />
              </div>
              {/* On Hover */}

              {edit && (
                <div className={classes.editAndDelete}>
                  <Button
                    className={classes.icon}
                    onClick={() => {
                      onDelete(idx);
                    }}
                    leftIcon={<RiDeleteBinLine />}
                  />

                  <Button
                    className={classes.icon}
                    onClick={() => {
                      inputRef.current.click();
                    }}
                    leftIcon={<MdModeEdit />}
                  />
                </div>
              )}
            </div>
          ))}
          {data?.length >= 4 && (
            <div
              style={{
                textAlign: 'center',
                color: 'grey',
                marginTop: '10px',
                fontSize: '24px',
              }}
            >
              {data?.length - 3} More
            </div>
          )}
        </>
      ) : (
        <div
          className={classes.csvBox}
          onClick={() => {
            edit && inputRef.current.click();
          }}
        >
          {edit ? (
            <>
              <FaFileUpload color={'var(--main-color)'} size={30} />
              <span className={classes?.uploadText}>upload</span>
            </>
          ) : (
            <FaFileCsv color={'var(--main-color)'} size={50} />
          )}
        </div>
      )}
      {/* Input For Image Upload */}
      <input
        hidden
        multiple
        type={'file'}
        ref={inputRef}
        onChange={(e) => HandleUploadFile(e)}
      />
    </div>
  );
}

export default AttachmentUpload;
